body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont , 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=FAMILY_NAME:wght@WEIGHT_OR_RANGE&display=swap');

.topNavbarColor{
    background-color: #ffffff !important;
}

#navbarSignInBtn{
    background-color: #040DBF;
    border-radius: 5px;
    font-family: 'Lato';
    font-size: 12px;
}

.homeImage{
    background-image: url('../public/assets/images/home/home.jpg');
    background-size: cover;
    background-position: center center;
    height: 900px;
    width: 1920px;
}

#cyberghanaNameSize{
    color: #ffffff !important;
    font-size: 55px !important;
    font-family: 'Lato';
    font-weight: 300;
}

#registerBtn{
    background-color: #ffffff;
    color: #020659;
    border-radius: 5px;
    font-family: 'Lato';
    font-size: 14px;
}

#SignInBtn{
    background-color: #ffffff;
    color: #020659;
    border-radius: 5px;
    font-family: 'Lato';
    font-size: 14px;
    position: relative;
    left: 1%;
}

#btnGroup{
    position: relative;
    left: 14%;
}

.fea{
    color: #020659;
}

#accessiblePlateformBg{
    background-image: url('../public/assets/images/home/accessible.jpg');
    background-size: cover;
    background-position: center center;
    /* height: 536px; */
    width: 1920px;
}

.accessbileUnderline{
    width: 30%;
    border: solid 1px #ffffff;
}

#accessibleNameSize{
    font-size: 50px;
    font-family: 'Lato';
    font-weight: 300;
}

.learnAnywhereLine, .HandsonLine{
    width: 50%;
    border: solid 1px #020659;
}

.EasyUseLine{
    width: 30%;
    border: solid 1px #020659;
}

.title{
    color: #020659;
}

.features .icon, .key-feature .icon{
    position: relative;
    top: -50px;
    background-color: #FAEEB5 !important;
}

#internshipNameSize{
    font-size: 65px;
    font-family: 'Lato';
    font-weight: 400;
}

.readMore{
    font-size: 12px !important;
    border: solid 1px #040DBF;
    color: #040DBF;
    cursor: pointer;
    border-radius: 5px;
}

.readMore:hover{
    border: solid 1px #040DBF;
    background-color: #040DBF;
    color: #ffffff;
    cursor: pointer;
}

#firthSection{
    background: #f7f7f7;
}

#cyberghanaTrainingNameSize{
    font-size: 30px !important;
    font-weight: 'Lato';
    color: #020659;
}

#contactUsBtn{
    font-size: 13px;
    background-color: #040DBF;
    color: #ffffff;
}

.contactUsBtn:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.cyberghanaLogoFooterText{
    color: #020659;
}

.icons{
    width: 45px;
    height: 45px;
}

.contactIcon{
    position: relative;
    right: 2%;
    width: 20px;
    height: 20px;
}

/* About Us Styling */
.aboutHomeImage{
    background-image: url('../public/assets/images/about/3.jpg');
}

#aboutUsTitle{
    color: #ffffff !important;
    /* font-size: 65px !important; */
    font-family: 'Lato';
    font-weight: 400;
}

#whoWeAreTitle{
    color: #020659;
    /* font-weight: 400; */
}

#aboutUsThirdSectionBg, #thirdSectionInternship{
    background-color: #f7f7f7;
}

/* #africanHudParagraphSize, #consultingAdvisoryParagraphSize, #visionParagraphSize, #missionParagraphSize, #coreValueParagraphSize{
    font-size: 17px;
} */

.africanHudUnderline, .consultingAdvisoryLine{
    width: 30%;
}

#registrationProcess{
    background-color: #020659 !important;
}

.cypher{
    position: relative;
    right: 3.5% !important;
    color: #020659 !important;
}

#firthSectionBg{
    background-color: #f7f7f7;
}

.boderRadius{
    border-radius: 10px;
}

/* Internship Page Styling */
.internshipHomeImage{
    background-image: url('../public/assets/images/internship/internship.jpg');
}

.raise:hover,
.raise:focus {
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transform: translateY(-0.25em);
}


/* Courses Css */
.CoursesHomeImage{
    background-image: url('../public/assets/images/courses/courses.jpg');
}

.isc2HomeImage{
    background-image: url('../public/assets/images/courses/isc2/home.jpg');
}

.expolreUnderline{
    width: 25%;
}

#lesson{
    min-width: 100px
}

#EnrollNowBtn{
    font-size: 13px;
    background-color: #040DBF;
    color: #ffffff;
}

#EnrollBtn{
    float: right;
}

.isacaHomeImage{
    background-image: url('../public/assets/images/courses/isaca.jpeg');
}

.comptiaHomeImage{
    background-image: url('../public/assets/images/courses/CompTIA.jpeg');
    background-position: center;
    background-size: cover;
}

.profesionalDevHomeImage{
    background-image: url('../public/assets/images/courses/Prof.jpg');
}

.galleryHomeImage{
    background-image: url('../public/assets/images/gallery/gal.jpg');
}

.contactHomeImage{
    background-image: url('../public/assets/images/contact/contact.jpg');
}

#submitBtn{
    background-color: #040DBF;
    color: #fff;
    border: none;
}

#submitBtn:hover{
    transform: translateY(-0.15em);
    box-shadow: #040DBF;
    border: none;
    background-color: #020659;
}

.telStyling{
    color: #040DBF !important;
    font-weight: 600 !important;
}

.hr{
    width: 100%;
}

#homeBtn{
    background-color: #040DBF;
    border: none;
    
}

#homeBtn:hover{
    transform: translateY(-0.15em);
}

#loginPage{
    z-index: 1 
}

#authHomeImage{
    background-image: url('../public/assets/images/auth/auth.jpg');
    border-top-left-radius: 40px !important;
}

#welcomeCyberghana{
    font-weight: 700 !important;
}

#AuthBtnn{
    background-color: #020659;
    border: none;
}

#AuthBtnn:hover{
    transform: translateY(-0.15em);
    border: none;
}

#topLogo{
    width: 200px;
    position: relative;
    top: -80px;
}

.ncccHomeImage{
    background-image: url('../public/assets/images/nccc/nccc.jpg');
}

.compititionHomeImage{
    background-image: url('../public/assets/images/compitition/compitition.jpg');
}

.videoPlay{
    z-index: 1; 
}

.card{
    cursor: pointer;
}

#sidebarContent{
    height: calc(100% - 60px);
}

#styleDrop{
    height: 100px !important;
}

.dd-menu{
    height: 320px; width: 290px;
}

#table-responsive{
    height: 545px;
}

#border-bottom{
    min-width: 220px;
}


#tableMain{
    border-bottom: solid 2px #040DBF;
}

.uil-estate{
    color: #040DBF;
}

.uil-sign-out-alt{
    color: red;

}

.CyberLabs{
    float: left;
    font-size: 13px;
}

.Craft{
    float: right;
    font-size: 13px;
}



/* New style */
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&amp;display=swap);

body {
    font-family: Nunito, sans-serif;
    overflow-x: hidden !important;
    font-size: 1rem;
    color: #212529;
    background-color: #fff
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--bs-font-sans-serif);
    line-height: 1.4;
    font-weight: 600
}

::-moz-selection {
    background: rgba(47, 85, 212, .9);
    color: #fff
}

::selection {
    background: rgba(47, 85, 212, .9);
    color: #fff
}

a {
    text-decoration: none !important;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

p {
    line-height: 1.6
}

.page-wrapper {
    /* height: 100vh */
}

.page-wrapper .sidebar-wrapper {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    background-color: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 300px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999
}

@media (min-width:1025px) {
    .page-wrapper .sidebar-wrapper {
        z-index: 1000
    }
}

@media (max-width:1024px) {
    .page-wrapper .sidebar-wrapper {
        left: 0
    }
}

@media (max-width:425px) {
    .page-wrapper .sidebar-wrapper {
        width: 260px
    }
}

.page-wrapper .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.page-wrapper .sidebar-wrapper a {
    text-decoration: none
}

.page-wrapper .sidebar-wrapper .sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    position: relative
}

.page-wrapper .sidebar-wrapper .sidebar-content.desktop {
    overflow-y: hidden
}

.page-wrapper .sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 71px
}

.page-wrapper .sidebar-wrapper .sidebar-brand>a {
    text-transform: uppercase;
    font-weight: 700;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .sidebar-wrapper .sidebar-brand>a:focus {
    outline: 0
}

.page-wrapper .sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 24px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden
}

.page-wrapper .sidebar-wrapper .sidebar-menu {
    padding: 16px 0
}

.page-wrapper .sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: 700;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 24px 8px 20px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:1024px) {
    .page-wrapper .sidebar-wrapper .sidebar-menu li a {
        padding: 8px 24px 8px 24px
    }
}

@media screen and (max-width:425px) {
    .page-wrapper .sidebar-wrapper .sidebar-menu li a {
        padding: 8px 24px 8px 12px
    }
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a:hover>i::before {
    display: inline-block
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a:focus {
    outline: 0
}

.page-wrapper .sidebar-wrapper .sidebar-menu li a i {
    width: 36px;
    height: 36px;
    background-color: #f8f9fa;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .sidebar-wrapper .sidebar-menu li.active i,
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover i {
    background-color: #f5f6f8
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
    content: "\f0142" !important;
    color: #212529;
    font-family: "Material Design Icons";
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 17px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 17px
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after,
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown:hover>a:after {
    color: #2f55d4
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu {
    display: none
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li {
    padding-left: 25px;
    font-size: 14px
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
    content: "\f09df" !important;
    color: #212529;
    font-family: "Material Design Icons";
    margin-right: 4px;
    font-size: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a .badge,
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a .label {
    float: right;
    margin-top: 0
}

.page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.badge,
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.label {
    float: right;
    margin-top: 8px;
    margin-left: 5px
}

.page-wrapper .sidebar-wrapper.sidebar-dark {
    background-color: #161c2d
}

.page-wrapper .sidebar-wrapper.sidebar-colored {
    background-color: #2f55d4
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .sidebar-colored,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .sidebar-colored {
    display: inline-block
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-dark-mode,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-dark-mode {
    display: none !important
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-light-mode,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-light-mode {
    display: none !important
}

.page-wrapper .sidebar-wrapper.sidebar-colored .btn.btn-icon,
.page-wrapper .sidebar-wrapper.sidebar-dark .btn.btn-icon {
    background-color: rgba(248, 249, 250, .03) !important;
    color: rgba(248, 249, 250, .5) !important;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu {
    border-top-color: rgba(255, 255, 255, .03)
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand>a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand>a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a {
    color: rgba(255, 255, 255, .5)
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand>a:hover,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown.active>a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active>a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active>a:before,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:active>a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:active>a:before,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover>a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover>a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand>a:hover,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown.active>a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active>a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active>a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active>a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active>a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover>a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover>a:before {
    color: #fff !important
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a i {
    background-color: rgba(248, 249, 250, .03)
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active i,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active i,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover i {
    background-color: rgba(248, 249, 250, .05)
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown>a:after,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown>a:after {
    color: rgba(255, 255, 255, .1)
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown.active>a:after,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown:hover>a:after,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown.active>a:after,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown:hover>a:after {
    color: #fff
}

.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
    color: rgba(255, 255, 255, .5)
}

.page-wrapper .sidebar-wrapper .sidebar-header,
.page-wrapper .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #e9ecef
}

.page-wrapper .sidebar-wrapper .sidebar-brand>a,
.page-wrapper .sidebar-wrapper .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper .sidebar-menu li a {
    color: #161c2d;
    font-weight: 600
}

.page-wrapper .sidebar-wrapper .sidebar-brand>a:hover,
.page-wrapper .sidebar-wrapper .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.page-wrapper .sidebar-wrapper .sidebar-menu li.active>a,
.page-wrapper .sidebar-wrapper .sidebar-menu li.active>a:before,
.page-wrapper .sidebar-wrapper .sidebar-menu li:active>a,
.page-wrapper .sidebar-wrapper .sidebar-menu li:active>a:before,
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover>a,
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover>a:before {
    color: #2f55d4 !important
}

.page-wrapper .sidebar-wrapper .sidebar-brand .sidebar-colored {
    display: none
}

.page-wrapper .sidebar-wrapper .sidebar-brand .logo-dark-mode {
    display: none
}

.page-wrapper .sidebar-wrapper .sidebar-brand .logo-light-mode {
    display: inline-block
}

.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0;
    overflow-x: hidden;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.page-wrapper .page-content .top-header {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    max-height: 70px;
    left: 0;
    -webkit-transition: all .3s;
    transition: all .3s
}

.page-wrapper .page-content .top-header .header-bar {
    background: #fff;
    height: 71px;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    position: relative;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px
}

@media (max-width:425px) {
    .page-wrapper .page-content .top-header .header-bar {
        padding: 24px 12px
    }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon {
    display: none
}

@media (max-width:1024px) {
    .page-wrapper .page-content .top-header .header-bar .logo-icon {
        display: block
    }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .small {
    display: none
}

@media (max-width:767px) {
    .page-wrapper .page-content .top-header .header-bar .logo-icon .small {
        display: block
    }
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .big {
    display: none
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .big .logo-dark-mode {
    display: none
}

.page-wrapper .page-content .top-header .header-bar .logo-icon .big .logo-light-mode {
    display: inline-block
}

@media (min-width:768px) and (max-width:1024px) {
    .page-wrapper .page-content .top-header .header-bar .logo-icon .big {
        display: block
    }
}

.page-wrapper .page-content .top-header .header-bar .search-bar {
    padding-top: 14.5px;
    padding-bottom: 14.5px
}

.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search form {
    position: relative
}

.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search input[type=text],
.page-wrapper .page-content .top-header .header-bar .search-bar .searchform input[type=text] {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 10px 12px;
    height: 36px;
    font-size: 14px;
    display: block;
    width: 250px;
    outline: 0 !important;
    padding-right: 45px;
    opacity: .7
}

.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search input[type=submit],
.page-wrapper .page-content .top-header .header-bar .search-bar .searchform input[type=submit] {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0;
    width: 40px;
    height: 40px
}

.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search .searchform:after {
    content: "\f0349";
    position: absolute;
    font-family: "Material Design Icons";
    right: 14px;
    top: 10px;
    font-size: 20px;
    line-height: 20px;
    pointer-events: none
}

.page-wrapper .page-content .layout-specing {
    padding: 94px 14px 24px !important;
    min-height: calc(100vh - 66px) !important;
    position: relative
}

@media (max-width:425px) {
    .page-wrapper .page-content .layout-specing {
        padding: 94px 0 24px !important
    }
}

@media screen and (min-width:1025px) {
    .page-wrapper.toggled .page-content {
        padding-left: 300px
    }
}

.page-wrapper.toggled .top-header {
    left: 301px
}

@media (max-width:1024px) {
    .page-wrapper.toggled .top-header {
        left: 0
    }
}

.page-wrapper.toggled .sidebar-wrapper {
    left: 0
}

@media (max-width:1024px) {
    .page-wrapper.toggled .sidebar-wrapper {
        left: -300px
    }
}

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 9px 24px 9px 20px !important;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    height: 58px
}

@media (max-width:425px) {
    .sidebar-footer {
        padding: 9px 12px !important
    }
}

[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

footer {
    position: relative;
    color: #adb5bd;
    background: #fff;
    height: 58px
}

footer .text-foot {
    color: #adb5bd
}

footer .footer-head {
    font-size: 18px !important;
    letter-spacing: 1px;
    font-weight: 500
}

footer .footer-list {
    margin-bottom: 0
}

footer .footer-list li {
    margin-bottom: 10px
}

footer .footer-list li a {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

footer .footer-list li a:hover {
    color: #e6e8ea
}

footer .footer-list li:last-child {
    margin-bottom: 0
}

.avatar.avatar-coin,
.avatar.avatar-sm-sm {
    height: 36px;
    width: 36px
}

.avatar.avatar-ex-sm {
    max-height: 25px
}

.avatar.avatar-ex-small {
    height: 36px;
    width: 36px
}

.avatar.avatar-md-sm {
    height: 45px;
    width: 45px
}

.avatar.avatar-small {
    height: 65px;
    width: 65px
}

.avatar.avatar-md-md {
    height: 80px;
    width: 80px
}

.avatar.avatar-medium {
    height: 110px;
    width: 110px
}

.avatar.avatar-large {
    height: 140px;
    width: 140px
}

.avatar.avatar-ex-large {
    height: 180px;
    width: 180px
}

.accordion .accordion-item {
    overflow: hidden;
    border: 0;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15)
}

.accordion .accordion-item .accordion-button {
    font-weight: 600;
    text-align: left;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1rem 2.5rem 1rem 1.25rem
}

.accordion .accordion-item .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.accordion .accordion-item .accordion-button:before {
    content: "\f0143";
    display: block;
    font-family: "Material Design Icons";
    font-size: 16px;
    color: #2f55d4;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.accordion .accordion-item .accordion-button.collapsed {
    background-color: #fff;
    color: #212529 !important;
    -webkit-transition: all .5s;
    transition: all .5s
}

.accordion .accordion-item .accordion-button.collapsed:before {
    top: 15px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    color: #212529 !important
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
    color: #2f55d4
}

.accordion .accordion-item .accordion-button:after {
    display: none
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #fff, #fff);
    z-index: 9999999
}

#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

#preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2f55d4;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out
}

#preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.form-check-label,
.form-label {
    font-weight: 700;
    font-size: 14px
}

.form-control {
    border: 1px solid #e9ecef;
    font-size: 14px;
    line-height: 26px;
    border-radius: 6px;
    color: #212529 !important;
    text-align: left
}

.form-control:focus {
    border-color: #2f55d4;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control[readonly] {
    background-color: #fff
}

.form-control:disabled {
    background-color: #dee2e6
}

.form-control::-webkit-input-placeholder {
    color: #3c4858
}

.form-control::-moz-placeholder {
    color: #3c4858
}

.form-control:-ms-input-placeholder {
    color: #3c4858
}

.form-control::-ms-input-placeholder {
    color: #3c4858
}

.form-control::placeholder {
    color: #3c4858
}

.form-check-input {
    border: 1px solid #e9ecef;
    background-color: #fff
}

.form-check-input:focus {
    border-color: #2f55d4;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-check-input.form-check-input:checked {
    background-color: #2f55d4;
    border-color: #2f55d4
}

.form-icon .icons {
    position: absolute;
    top: 13px;
    left: 18px
}

.subcribe-form .btn {
    padding: 10px 20px
}

.subcribe-form input {
    padding: 12px 20px;
    width: 100%;
    color: #212529 !important;
    border: none;
    outline: 0 !important;
    padding-right: 160px;
    padding-left: 30px;
    background-color: rgba(255, 255, 255, .8);
    height: 50px
}

.subcribe-form input::-webkit-input-placeholder {
    color: #3c4858
}

.subcribe-form input::-moz-placeholder {
    color: #3c4858
}

.subcribe-form input:-ms-input-placeholder {
    color: #3c4858
}

.subcribe-form input::-ms-input-placeholder {
    color: #3c4858
}

.subcribe-form input::placeholder {
    color: #3c4858
}

.subcribe-form button {
    position: absolute;
    top: 2px;
    right: 3px;
    outline: 0 !important
}

.subcribe-form form {
    position: relative;
    max-width: 600px;
    margin: 0 auto
}

.blog.blog-primary .tag {
    background: #2f55d4 !important
}

.blog.blog-primary .title:hover {
    color: #2f55d4 !important
}

.blog.blog-primary .link {
    color: #2f55d4 !important
}

.blog.blog-primary .post-meta .comments:hover,
.blog.blog-primary .post-meta .like:hover,
.blog.blog-primary .post-meta .readmore:hover {
    color: #2f55d4 !important
}

.blog.blog-secondary .tag {
    background: #6c757d !important
}

.blog.blog-secondary .title:hover {
    color: #6c757d !important
}

.blog.blog-secondary .link {
    color: #6c757d !important
}

.blog.blog-secondary .post-meta .comments:hover,
.blog.blog-secondary .post-meta .like:hover,
.blog.blog-secondary .post-meta .readmore:hover {
    color: #6c757d !important
}

.blog.blog-success .tag {
    background: #2eca8b !important
}

.blog.blog-success .title:hover {
    color: #2eca8b !important
}

.blog.blog-success .link {
    color: #2eca8b !important
}

.blog.blog-success .post-meta .comments:hover,
.blog.blog-success .post-meta .like:hover,
.blog.blog-success .post-meta .readmore:hover {
    color: #2eca8b !important
}

.blog.blog-info .tag {
    background: #17a2b8 !important
}

.blog.blog-info .title:hover {
    color: #17a2b8 !important
}

.blog.blog-info .link {
    color: #17a2b8 !important
}

.blog.blog-info .post-meta .comments:hover,
.blog.blog-info .post-meta .like:hover,
.blog.blog-info .post-meta .readmore:hover {
    color: #17a2b8 !important
}

.blog.blog-warning .tag {
    background: #f17425 !important
}

.blog.blog-warning .title:hover {
    color: #f17425 !important
}

.blog.blog-warning .link {
    color: #f17425 !important
}

.blog.blog-warning .post-meta .comments:hover,
.blog.blog-warning .post-meta .like:hover,
.blog.blog-warning .post-meta .readmore:hover {
    color: #f17425 !important
}

.blog.blog-danger .tag {
    background: #e43f52 !important
}

.blog.blog-danger .title:hover {
    color: #e43f52 !important
}

.blog.blog-danger .link {
    color: #e43f52 !important
}

.blog.blog-danger .post-meta .comments:hover,
.blog.blog-danger .post-meta .like:hover,
.blog.blog-danger .post-meta .readmore:hover {
    color: #e43f52 !important
}

.blog.blog-light .tag {
    background: #f8f9fa !important
}

.blog.blog-light .title:hover {
    color: #f8f9fa !important
}

.blog.blog-light .link {
    color: #f8f9fa !important
}

.blog.blog-light .post-meta .comments:hover,
.blog.blog-light .post-meta .like:hover,
.blog.blog-light .post-meta .readmore:hover {
    color: #f8f9fa !important
}

.blog.blog-dark .tag {
    background: #212529 !important
}

.blog.blog-dark .title:hover {
    color: #212529 !important
}

.blog.blog-dark .link {
    color: #212529 !important
}

.blog.blog-dark .post-meta .comments:hover,
.blog.blog-dark .post-meta .like:hover,
.blog.blog-dark .post-meta .readmore:hover {
    color: #212529 !important
}

.blog {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.blog .content h4 {
    line-height: 1.2
}

.blog .content .title {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.blog .content .post-meta .comments,
.blog .content .post-meta .like,
.blog .content .post-meta .readmore {
    font-size: 15px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.blog .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    background: #3c4858
}

.blog .author {
    position: absolute;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    top: 5%;
    left: 5%
}

.blog.blog-detail:hover {
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important
}

.blog:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.blog:hover .overlay {
    opacity: .4
}

.blog:hover .author {
    opacity: 1
}

.sidebar .widget .post-recent {
    padding-bottom: 15px
}

.sidebar .widget .post-recent:last-child {
    padding-bottom: 0
}

.sidebar .widget .post-recent .post-recent-thumb {
    width: 25%
}

.sidebar .widget .post-recent .post-recent-content {
    width: 75%;
    padding-left: 10px
}

.sidebar .widget .post-recent .post-recent-content a {
    display: block;
    color: #212529;
    font-size: 15px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.sidebar .widget .post-recent .post-recent-content a:hover {
    color: #2f55d4
}

.sidebar .widget .post-recent .post-recent-content span {
    font-size: 13px
}

.tagcloud>a {
    background: #f8f9fa;
    color: #212529;
    display: inline-block;
    font-size: 9px;
    letter-spacing: 1px;
    margin: 5px 10px 5px 0;
    padding: 8px 12px 7px;
    text-transform: uppercase;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    font-weight: 600
}

.tagcloud>a:hover {
    background: #2f55d4;
    color: #fff
}

.media-list .media .media-heading:hover {
    color: #2f55d4 !important
}

.media-list .sub-comment {
    border-left: 2px dashed #e9ecef
}

.widget-grid .item {
    position: relative;
    width: 33.33333333%;
    padding-top: 33.33333333%;
    float: left
}

.widget-grid .item a {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    overflow: hidden
}

.widget-grid .item:first-child {
    width: 66.66666666%;
    padding-top: 66.66666666%
}

.widget-grid .item:nth-child(4) {
    clear: both
}

#external-events .fc-event,
.fc-event-title-container {
    cursor: pointer;
    margin: 4px 2px
}

#external-events .fc-event .fc-event-main {
    padding: 5px
}

.fc .fc-non-business {
    background-color: transparent
}

.fc .fc-daygrid-day-number {
    padding: 5px;
    font-weight: 600;
    color: #2f55d4
}

.fc .fc-col-header-cell-cushion {
    padding: 8px 4px !important;
    color: #2f55d4
}

.fc .fc-scrollgrid-liquid {
    border-radius: 5px !important
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
    border-color: #e9ecef !important
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 18px !important
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
    margin: auto 8px 4px
}

.fc-event-main {
    background-color: #2f55d4
}

.fc-daygrid-dot-event {
    background: #f8f9fa;
    border: 1px solid #e9ecef
}

.fc-daygrid-dot-event:hover {
    background-color: #e9ecef
}

.fc .fc-button {
    padding: 4px 8px;
    font-size: 13px !important;
    background-color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
    color: #fff !important;
    text-transform: capitalize !important
}

.fc .fc-button:disabled,
.fc .fc-button:disabled.active,
.fc .fc-button:disabled.focus,
.fc .fc-button:disabled:active,
.fc .fc-button:disabled:focus,
.fc .fc-button:disabled:hover {
    background-color: rgba(47, 85, 212, .1) !important;
    border: 1px solid rgba(47, 85, 212, .1) !important;
    color: #2f55d4 !important
}

.fc .fc-button.active,
.fc .fc-button.focus,
.fc .fc-button:active,
.fc .fc-button:focus,
.fc .fc-button:hover {
    background-color: #284bc1 !important;
    border-color: #284bc1 !important
}

.fc .fc-button:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.fc .fc-icon {
    font-size: 16px !important
}

.fc-daygrid-event-dot,
.fc-h-event {
    border-color: #2f55d4;
    background-color: #2f55d4
}

#calendar {
    max-width: 1240px
}

.chat .chat-list.active,
.chat .chat-list:focus,
.chat .chat-list:hover {
    background: #f8f9fa !important;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15)
}

.chat .chat-list .on-off,
.chat .chat-type .on-off {
    position: absolute;
    left: -1px;
    top: -1px;
    font-size: 12px !important
}

.chat.chat-person .on-off {
    font-size: 10px
}

.chat.chat-person .chat-msg {
    margin-left: 8px
}

.chat.chat-person .chat-msg .msg {
    background: #fff
}

.chat.chat-person .chat-msg .msg-time {
    font-size: 12px !important
}

.chat.chat-person .chat-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right
}

.chat.chat-person .chat-right .chat-user-image {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.chat.chat-person .chat-right .chat-user-image .on-off {
    right: -1px
}

.chat.chat-person .chat-right .chat-msg {
    margin-right: 8px;
    margin-left: 0
}

.chat.chat-person .chat-right .chat-msg .msg {
    display: inline-block
}

.chat.chat-person .chat-right .chat-msg .msg-time {
    display: block
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
    background: #fff !important;
    border: 1px solid #e9ecef !important
}

.ck.ck-toolbar>.ck-toolbar__items>:not(.ck-toolbar__line-break),
.ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown {
    color: #212529
}

.features.feature-primary .title:hover {
    color: #2f55d4 !important
}

.features.feature-primary .icon {
    color: #2f55d4 !important;
    background-color: rgba(47, 85, 212, .04) !important
}

.features.feature-primary .link {
    color: #2f55d4 !important
}

.features.feature-primary.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, .1)) !important
}

.features.feature-bg-primary:hover {
    background-color: #2f55d4 !important
}

.features.feature-secondary .title:hover {
    color: #6c757d !important
}

.features.feature-secondary .icon {
    color: #6c757d !important;
    background-color: rgba(108, 117, 125, .04) !important
}

.features.feature-secondary .link {
    color: #6c757d !important
}

.features.feature-secondary.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(108, 117, 125, .1)) !important
}

.features.feature-bg-secondary:hover {
    background-color: #6c757d !important
}

.features.feature-success .title:hover {
    color: #2eca8b !important
}

.features.feature-success .icon {
    color: #2eca8b !important;
    background-color: rgba(46, 202, 139, .04) !important
}

.features.feature-success .link {
    color: #2eca8b !important
}

.features.feature-success.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(46, 202, 139, .1)) !important
}

.features.feature-bg-success:hover {
    background-color: #2eca8b !important
}

.features.feature-info .title:hover {
    color: #17a2b8 !important
}

.features.feature-info .icon {
    color: #17a2b8 !important;
    background-color: rgba(23, 162, 184, .04) !important
}

.features.feature-info .link {
    color: #17a2b8 !important
}

.features.feature-info.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(23, 162, 184, .1)) !important
}

.features.feature-bg-info:hover {
    background-color: #17a2b8 !important
}

.features.feature-warning .title:hover {
    color: #f17425 !important
}

.features.feature-warning .icon {
    color: #f17425 !important;
    background-color: rgba(241, 116, 37, .04) !important
}

.features.feature-warning .link {
    color: #f17425 !important
}

.features.feature-warning.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(241, 116, 37, .1)) !important
}

.features.feature-bg-warning:hover {
    background-color: #f17425 !important
}

.features.feature-danger .title:hover {
    color: #e43f52 !important
}

.features.feature-danger .icon {
    color: #e43f52 !important;
    background-color: rgba(228, 63, 82, .04) !important
}

.features.feature-danger .link {
    color: #e43f52 !important
}

.features.feature-danger.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(228, 63, 82, .1)) !important
}

.features.feature-bg-danger:hover {
    background-color: #e43f52 !important
}

.features.feature-light .title:hover {
    color: #f8f9fa !important
}

.features.feature-light .icon {
    color: #f8f9fa !important;
    background-color: rgba(248, 249, 250, .04) !important
}

.features.feature-light .link {
    color: #f8f9fa !important
}

.features.feature-light.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(248, 249, 250, .1)) !important
}

.features.feature-bg-light:hover {
    background-color: #f8f9fa !important
}

.features.feature-dark .title:hover {
    color: #212529 !important
}

.features.feature-dark .icon {
    color: #212529 !important;
    background-color: rgba(33, 37, 41, .04) !important
}

.features.feature-dark .link {
    color: #212529 !important
}

.features.feature-dark.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(33, 37, 41, .1)) !important
}

.features.feature-bg-dark:hover {
    background-color: #212529 !important
}

.features {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    background-color: #fff
}

.features .icon {
    height: 65px;
    width: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.features .title {
    font-weight: 500
}

.features .big-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    opacity: .05;
    font-size: 120px
}

.features .big-icon,
.features .icons,
.features .para,
.features .titles {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.features:hover .big-icon,
.features:hover .icons,
.features:hover .titles {
    color: #fff !important
}

.features:hover .para {
    color: rgba(255, 255, 255, .5) !important
}

.key-feature .icon {
    height: 45px;
    width: 45px;
    line-height: 45px
}

.fw-medium {
    font-weight: 600
}

.section {
    padding: 100px 0;
    position: relative
}

@media (max-width:768px) {
    .section {
        padding: 60px 0
    }
}

.bg-overlay {
    background-color: rgba(60, 72, 88, .7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-white {
    background-color: rgba(255, 255, 255, .5)
}

.title-heading {
    line-height: 26px
}

.title-heading .heading {
    font-size: 45px !important;
    letter-spacing: 1px
}

@media (max-width:768px) {
    .title-heading .heading {
        font-size: 35px !important
    }
}

.title-heading .para-desc {
    font-size: 18px
}

.section-title .title {
    letter-spacing: .5px;
    font-size: 30px !important
}

@media (max-width:768px) {
    .section-title .title {
        font-size: 24px !important
    }
}

.para-desc {
    max-width: 600px
}

.mt-100 {
    margin-top: 100px
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0
}

.shape>svg {
    -webkit-transform: scale(2);
    transform: scale(2);
    width: 100%;
    height: auto;
    -webkit-transform-origin: top center;
    transform-origin: top center
}

.shape.integration-hero {
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    height: 250px;
    background: #fff
}

@media (max-width:768px) {
    .shape.integration-hero {
        height: 140px
    }
}

@media (max-width:425px) {
    .shape.integration-hero {
        height: 60px
    }
}

@media (max-width:425px) {
    .shape {
        bottom: -4px
    }
}

@media (min-width:768px) {
    .margin-top-100 {
        margin-top: 100px
    }
}

@media (max-width:768px) {
    .mt-60 {
        margin-top: 60px
    }
}

.main-icon {
    border: 2px solid #e9ecef;
    height: 40px;
    width: 40px;
    line-height: 0;
    text-align: center
}

.main-icon .fea {
    height: 20px
}

@media (min-width:768px) {
    .main-icon {
        margin: 0 auto
    }
}

.timeline-page:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 95%;
    margin: auto;
    width: 2px;
    background-color: #e9ecef !important
}

.timeline-page .timeline-item .date-label-left,
.timeline-page .timeline-item .duration-right {
    margin-right: 15px
}

.timeline-page .timeline-item .date-label-left:after,
.timeline-page .timeline-item .duration-right:after {
    content: "";
    position: absolute;
    top: 12px;
    width: 12px;
    height: 12px;
    z-index: 1;
    background-color: #2f55d4;
    border-radius: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.timeline-page .timeline-item .date-label-left {
    float: right;
    margin-right: 15px
}

.timeline-page .timeline-item .date-label-left:after {
    right: -34px
}

.timeline-page .timeline-item .duration-right {
    float: left;
    margin-left: 15px
}

.timeline-page .timeline-item .duration-right:after {
    left: -34px
}

.timeline-page .timeline-item .event {
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.timeline-page .timeline-item .event.event-description-right {
    margin-left: 15px
}

.timeline-page .timeline-item .event.event-description-left {
    margin-right: 15px;
    text-align: right
}

@media (max-width:767px) {
    .timeline-page {
        margin-left: 18px
    }

    .timeline-page:after {
        margin: 0
    }

    .timeline-page .timeline-item .duration {
        float: left !important;
        margin: 0 0 30px 15px !important;
        text-align: left !important
    }

    .timeline-page .timeline-item .duration:after {
        left: -21px !important
    }

    .timeline-page .timeline-item .duration .event {
        text-align: left !important;
        margin-left: 15px
    }

    .timeline-page .timeline-item .event-description-left {
        text-align: left !important;
        margin: 0 0 0 15px
    }
}

.back-to-home {
    position: fixed;
    top: 4%;
    right: 2%;
    z-index: 1
}

.sticky-bar {
    position: sticky;
    top: 80px
}

.fea {
    stroke-width: 1.8
}

.fea.icon-sm {
    height: 16px;
    width: 16px
}

.fea.icon-ex-md {
    height: 20px;
    width: 20px
}

.fea.icon-m-md {
    height: 28px;
    width: 28px
}

.fea.icon-md {
    height: 35px;
    width: 35px
}

.fea.icon-lg {
    height: 42px;
    width: 42px
}

.fea.icon-ex-lg {
    height: 52px;
    width: 52px
}

[class*=" uil-"]:before,
[class^=uil-]:before {
    margin: 0
}

.uim-svg {
    vertical-align: 0 !important
}

.social-icon li a {
    color: #212529;
    border: 1px solid #212529;
    height: 32px;
    width: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    overflow: hidden;
    position: relative
}

.social-icon li a .fea-social {
    stroke-width: 2
}

.social-icon li a:hover {
    background-color: #2f55d4;
    border-color: #2f55d4 !important;
    color: #fff !important
}

.social-icon li a:hover .fea-social {
    fill: #2f55d4
}

.social-icon.social li a {
    color: #adb5bd;
    border-color: #adb5bd
}

.social-icon.foot-social-icon li a {
    color: #adb5bd;
    border-color: #283453
}

.rounded {
    border-radius: 6px !important
}

.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important
}

.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important
}

.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important
}

.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important
}

.rounded-md {
    border-radius: 10px !important
}

.rounded-lg {
    border-radius: 30px !important
}

.border-primary {
    border-color: #2f55d4 !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #2eca8b !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #f17425 !important
}

.border-danger {
    border-color: #e43f52 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #212529 !important
}

.bg-white-color {
    background: #fff
}

.d-flex .flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.bg-home {
    height: 100vh;
    background-size: cover;
    -ms-flex-item-align: center;
    align-self: center;
    position: relative;
    background-position: center center
}

@media (max-width:767px) {
    .bg-home {
        padding: 150px 0;
        height: auto
    }
}

.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba(47, 85, 212, .1) 0, rgba(47, 85, 212, .1) 33.333%, rgba(47, 85, 212, .2) 33.333%, rgba(47, 85, 212, .2) 66.666%, rgba(47, 85, 212, .3) 66.666%, rgba(47, 85, 212, .3) 99.999%)
}

.pricing-rates {
    position: relative;
    z-index: 1;
    border-bottom: 3px solid #e9ecef !important;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.pricing-rates .price {
    font-weight: 400
}

.pricing-rates.business-rate:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    z-index: 2;
    border-color: #2f55d4 !important;
    -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
    box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
    background-color: #fff !important
}

.pricing-rates.business-rate:hover .title {
    color: #2f55d4
}

.ribbon.ribbon-primary span {
    background-color: #2f55d4 !important
}

.ribbon.ribbon-primary span:before {
    border-left-color: #2443ac;
    border-top-color: #2443ac
}

.ribbon.ribbon-primary span:after {
    border-right-color: #2443ac;
    border-top-color: #2443ac
}

.ribbon.ribbon-secondary span {
    background-color: #6c757d !important
}

.ribbon.ribbon-secondary span:before {
    border-left-color: #545b62;
    border-top-color: #545b62
}

.ribbon.ribbon-secondary span:after {
    border-right-color: #545b62;
    border-top-color: #545b62
}

.ribbon.ribbon-success span {
    background-color: #2eca8b !important
}

.ribbon.ribbon-success span:before {
    border-left-color: #25a06e;
    border-top-color: #25a06e
}

.ribbon.ribbon-success span:after {
    border-right-color: #25a06e;
    border-top-color: #25a06e
}

.ribbon.ribbon-info span {
    background-color: #17a2b8 !important
}

.ribbon.ribbon-info span:before {
    border-left-color: #117a8b;
    border-top-color: #117a8b
}

.ribbon.ribbon-info span:after {
    border-right-color: #117a8b;
    border-top-color: #117a8b
}

.ribbon.ribbon-warning span {
    background-color: #f17425 !important
}

.ribbon.ribbon-warning span:before {
    border-left-color: #d55b0e;
    border-top-color: #d55b0e
}

.ribbon.ribbon-warning span:after {
    border-right-color: #d55b0e;
    border-top-color: #d55b0e
}

.ribbon.ribbon-danger span {
    background-color: #e43f52 !important
}

.ribbon.ribbon-danger span:before {
    border-left-color: #d21e32;
    border-top-color: #d21e32
}

.ribbon.ribbon-danger span:after {
    border-right-color: #d21e32;
    border-top-color: #d21e32
}

.ribbon.ribbon-light span {
    background-color: #f8f9fa !important
}

.ribbon.ribbon-light span:before {
    border-left-color: #dae0e5;
    border-top-color: #dae0e5
}

.ribbon.ribbon-light span:after {
    border-right-color: #dae0e5;
    border-top-color: #dae0e5
}

.ribbon.ribbon-dark span {
    background-color: #212529 !important
}

.ribbon.ribbon-dark span:before {
    border-left-color: #0a0c0d;
    border-top-color: #0a0c0d
}

.ribbon.ribbon-dark span:after {
    border-right-color: #0a0c0d;
    border-top-color: #0a0c0d
}

.ribbon {
    position: absolute;
    top: -5px;
    width: 75px;
    height: 75px;
    z-index: 2
}

.ribbon.ribbon-right {
    right: -5px
}

.ribbon.ribbon-right span {
    right: -23px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.ribbon.ribbon-left {
    left: -5px
}

.ribbon.ribbon-left span {
    left: -23px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.ribbon span {
    position: absolute;
    top: 18px;
    width: 100px;
    color: #fff
}

.ribbon span:after,
.ribbon span:before {
    content: "";
    position: absolute;
    top: 100%;
    z-index: -1;
    border: 3px solid transparent
}

.ribbon span:before {
    left: 0
}

.ribbon span:after {
    right: 0
}

.invoice-top .logo-invoice {
    font-weight: 700;
    font-size: 27px;
    letter-spacing: 1px
}

.switcher-pricing .toggle,
.switcher-pricing .toggler {
    display: inline-block !important;
    vertical-align: middle
}

.switcher-pricing .toggler {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    font-weight: 700
}

.switcher-pricing .toggler--is-active {
    color: #2f55d4 !important
}

.switcher-pricing .form-switch .form-check-input {
    width: 48px;
    height: 24px;
    margin-top: 0
}

.hide {
    display: none
}

.apexcharts-legend-text {
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #343a40 !important
}

.apexcharts-tooltip {
    background: #fff !important;
    color: #343a40;
    border-color: #e9ecef !important
}

.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-grid,
.apexcharts-gridline,
.apexcharts-gridline line,
.apexcharts-gridlines-horizontal,
.apexcharts-gridlines-vertical,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-radar-series line,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon,
.apexcharts-xaxis line,
.apexcharts-xaxis-tick {
    stroke: #e9ecef !important
}

.apexcharts-data-labels,
.apexcharts-datalabels text,
.apexcharts-xaxis text,
.apexcharts-yaxis text {
    font-family: var(--bs-font-sans-serif) !important;
    fill: #343a40 !important
}

.apexcharts-tooltip-title {
    background: #fff !important;
    color: #343a40 !important;
    border-color: #e9ecef !important
}

.shop-features .category-title {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 20px;
    z-index: 1
}

.shop-list .shop-image .overlay-work,
.shop-list .shop-image .shop-icons {
    position: absolute;
    opacity: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.shop-list .shop-image .overlay-work {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1
}

.shop-list .shop-image .overlay-work .out-stock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0
}

.shop-list .shop-image .shop-icons {
    top: 15px;
    right: 15px;
    z-index: 2
}

.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
    opacity: 1
}

.shop-list .content .product-name {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.shop-list .content .product-name:hover {
    color: #2f55d4 !important
}

.shop-list .label {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2
}

.shop-list .qty-btn {
    pointer-events: none;
    width: 65px;
    padding-left: 15px
}

.simplebar-track.simplebar-vertical {
    width: 8px !important
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    width: 4px !important;
    background: #adb5bd !important
}

.team.team-primary .name:hover {
    color: #2f55d4 !important
}

.team.team-secondary .name:hover {
    color: #6c757d !important
}

.team.team-success .name:hover {
    color: #2eca8b !important
}

.team.team-info .name:hover {
    color: #17a2b8 !important
}

.team.team-warning .name:hover {
    color: #f17425 !important
}

.team.team-danger .name:hover {
    color: #e43f52 !important
}

.team.team-light .name:hover {
    color: #f8f9fa !important
}

.team.team-dark .name:hover {
    color: #212529 !important
}

.team img {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.team .team-icon {
    position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 0;
    opacity: 0;
    left: 0;
    text-align: center;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.team:hover img {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    opacity: .2
}

.team:hover .overlay-team {
    opacity: .6
}

.team:hover .team-icon {
    opacity: 1
}

.tns-nav {
    text-align: center;
    margin-top: 10px
}

.tns-nav button {
    border-radius: 3px;
    background: rgba(47, 85, 212, .5) !important;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    border: none;
    margin: 0 5px;
    padding: 5px
}

.tns-nav button.tns-nav-active {
    background: #2f55d4 !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

html[dir=rtl] .swiper-slider-hero,
html[dir=rtl] .tns-inner,
html[dir=rtl] .tns-nav {
    direction: ltr !important
}

html[dir=rtl] .tns-visually-hidden {
    left: 0 !important;
    visibility: hidden
}

.login-page {
    font-size: 15px
}

.login-page .forgot-pass {
    z-index: 99;
    position: relative;
    font-size: 13px
}

.cover-user .container-fluid {
    max-width: 100%
}

@media (max-width:768px) {
    .cover-user .container-fluid .padding-less {
        position: relative !important;
        height: 400px !important
    }
}

.cover-user .container-fluid .padding-less.img {
    background: scroll center no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%
}

.cover-user .cover-user-img {
    height: 100vh
}

@media (min-width:1025px) {
    .cover-user .cover-user-img {
        padding: 0 20%
    }
}

@media (max-width:768px) {
    .cover-user .cover-user-img {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: start !important;
        height: auto !important;
        padding: 30px
    }
}

@media (max-width:768px) {
    .cover-user .cover-user-content {
        text-align: left !important
    }

    .cover-user .cover-my-30 {
        margin: 30px 0
    }
}

.form-signin {
    width: 100%;
    max-width: 400px;
    margin: auto
}

.avatar-profile {
    position: relative;
    top: -80px
}

.work-container.work-classic .work-image {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.work-container.work-classic .work-image:hover {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15);
    box-shadow: 0 0 3px rgba(60, 72, 88, .15)
}

.work-container.work-classic .content .title,
.work-container.work-modern .content .title {
    font-size: 18px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.work-container.work-classic .content .title:hover,
.work-container.work-modern .content .title:hover {
    color: #2f55d4 !important
}

.work-container.work-classic .content .tag,
.work-container.work-modern .content .tag {
    font-size: 15px !important
}

.work-container.work-modern img {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.work-container.work-modern:hover img {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg)
}

.tobii>button.tobii__close svg,
.tobii>button.tobii__next svg,
.tobii>button.tobii__prev svg {
    height: 36px;
    width: auto
}

.tobii__counter {
    font-size: 16px
}

.tobii-zoom {
    display: block !important
}

.tobii-zoom__icon {
    display: none
}

#grid {
    padding: 0 !important
}

.switcher-btn {
    position: fixed;
    top: 200px;
    height: 41px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.style-switcher .t-dark,
.style-switcher .t-rtl-light {
    display: inline-block
}

.style-switcher .t-light,
.style-switcher .t-ltr-dark,
.style-switcher .t-ltr-light,
.style-switcher .t-rtl-dark {
    display: none
}

@media only screen and (max-width:375px) {
    .switcher-btn {
        display: none !important
    }
}

[dir=rtl] .style-switcher .t-rtl-light {
    display: none
}

[dir=rtl] .style-switcher .t-ltr-light {
    display: inline-block
}

.scroll{
    overflow-x: scroll !important;
}
thead{
    font-size: 13px;
}

#tableMagenet{
width: 100% !important;
}

.truncate {
    max-width: 200px; /* Adjust the max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pointer{
    cursor: pointer;
  }

  .c{
    color: rgb(112,168,255);
  }

  .mg{
    margin-left: 5%;
    margin-top: 50%;
  }